import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hunter behavior has direct impact on public opinion`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "504px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ed0ea38fdec5a57303a4ea3ed1732a41/8e386/todd-geiler.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABKWD2lG1I/wD/xAAcEAABAwUAAAAAAAAAAAAAAAAAAgMSAQQRMTL/2gAIAQEAAQUCtu1YiN7dVWR//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BGf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAaEAABBQEAAAAAAAAAAAAAAAAAARARITFx/9oACAEBAAY/Al4U1KRkN//EABkQAAMBAQEAAAAAAAAAAAAAAAERIQAQMf/aAAgBAQABPyHACERXCIsDkyUI8rn/2gAMAwEAAgADAAAAEPQP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EBCTP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxCpX//EAB4QAQABAwUBAAAAAAAAAAAAAAERABAhMWFxkbHB/9oACAEBAAE/EBOKc/SiPIIVXO9n4iBKhhSmLwI0OfnVv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "todd geiler",
            "title": "todd geiler",
            "src": "/static/ed0ea38fdec5a57303a4ea3ed1732a41/8e386/todd-geiler.jpg",
            "srcSet": ["/static/ed0ea38fdec5a57303a4ea3ed1732a41/e07e9/todd-geiler.jpg 200w", "/static/ed0ea38fdec5a57303a4ea3ed1732a41/066f9/todd-geiler.jpg 400w", "/static/ed0ea38fdec5a57303a4ea3ed1732a41/8e386/todd-geiler.jpg 504w"],
            "sizes": "(max-width: 504px) 100vw, 504px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Arizona Game and Fish Commission`}</p>
    <p>{`“Ethical behavior is doing the right thing when no one else is watching — even when doing the wrong thing is legal.” — Aldo Leopold.`}</p>
    <p>{`I’ve always taken those sage words of Aldo Leopold, the “father of wildlife management,” to heart.`}</p>
    <p>{`As a lifelong sportsman from Prescott, I practice and preach the ethical code that responsible hunters have stood behind for generations — knowing and respecting game animals and their habitats, obeying laws that ensure hunting is conducted in a safe, responsible and sustainable manner, and behaving in the right way.`}</p>
    <p>{`What better time than now, as Arizona’s hunting seasons are getting underway, to gently remind hunters that the future of our time-honored tradition, as well as the conservation of natural resources, is irrevocably tied to their ethical conduct in the field`}</p>
    <p>{`Simply put, the ethics of hunters (and let’s not forget anglers) affect public opinion. While laws and regulations might help to conserve wildlife, it’s the ethical approach to hunting that helps to ensure future opportunities.`}</p>
    <p>{`Frankly, it’s about respecting natural resources and landowners, as well as other hunters and non-hunters alike. It’s why we proudly support the North American Model of Wildlife Conservation, the principles that guide wildlife management and conservation decisions in the U.S. and Canada. It’s why we stand together for fair chase, which is defined by the Boone and Crockett Club as “the ethical, sportsmanlike, lawful pursuit and taking of any free-ranging wild animal in a manner that does not give the hunter an improper or unfair advantage over such game animals.”`}</p>
    <p>{`Fortunately, the vast majority of hunters conduct themselves fairly, respectfully and responsibly, exhibiting values in the field that distinguish between right and wrong, both inside and outside of the laws — which brings us back to public opinion. According to a recent survey by the Center for Humans and Nature, about 79 percent of U.S. citizens indicate they support hunting, even if they don’t personally participate in the sport.`}</p>
    <p>{`Now that’s an endorsement in which hunters can take pride!`}</p>
    <p>{`My family has lived in Yavapai County since the 1940s. It’s where I maintain my optometric practice that my grandfather started in 1946 in Prescott. I balance my professional life (or at least try to) by getting outdoors and staying involved with great organizations like the Arizona Deer Association, National Rifle Association, Pope and Young Association, Granite Mountain Archers and more. It’s also an honor to be serving as the newest member of the Arizona Game and Fish Commission.`}</p>
    <p>{`Ask me what I’m most passionate about, what really matters most, and my answer would be volunteering to get young people more involved in outdoor activities and conservation. My hope, and that of my fellow commissioners, is to see more youngsters afield.`}</p>
    <p>{`The future of hunting and angling, as well as the conservation of natural resources heavily depends on the recruitment of youth, because Arizona sportsmen and sportswomen fund nearly 70 percent of wildlife conservation. It’s time for us to encourage young hunters by giving them a solid foundation — one that’s steeped in ethics — that will help to make them responsible hunters for life.`}</p>
    <p>{`When preparing for that next hunting adventure, remember to include the young people in your life. I promise, whether they harvest that first buck, quail or squirrel, or go home empty-handed, the experience will make for great story-telling and lasting memories.`}</p>
    <p>{`And, somewhere along the way, perhaps while resting under a tall pine or sitting on the edge of a quiet canyon, be sure to share with them the wisdom of Aldo Leopold.`}</p>
    <p>{`Dr. Todd Geiler of Prescott is a member of the Arizona Game and Fish Commission`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      